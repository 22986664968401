/**
 * 矢印アイコン
 * アニメーション用
 */
/** */
export const SvgArrowIconSizeM = () => {
  return (
    <svg
      className="animateArrowIcon"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="12.5"
        // transform="rotate(90 15 15)"
        stroke="#CC050B"
      />
      <path d="M15 20L12.4019 11.75L17.5981 11.75L15 20Z" fill="#CC050B" />
    </svg>
  )
}
