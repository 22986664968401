import { css } from '@emotion/react'
import { isInternalLink } from '../../hooks/isInternalLink'
import { hoverArrowIcon } from '../../styles/animation'
import { LinkBase } from '../Link/LinkBase'
import { SvgArrowIconSizeM } from '../Svg/SvgArrowIconSizeM'

interface Props {
  label: string
  url: string
}

/**
 * ボタン横長、矢印つき
 * @param {string} label 文言
 * @param {string} url url
 */
/** */
export const ButtonSecondary = ({ label, url }: Props) => {
  return (
    <LinkBase
      css={css`
        ${hoverArrowIcon}
        @media screen and (min-width: 1200px) {
          &:hover {
            svg.animateArrowIcon {
              circle {
                stroke: #fff;
              }
            }
            svg.externalIcon {
              path {
                fill: #fff;
              }
            }
          }
        }
      `}
      className="h-[78px] flex duration-default items-center px-4 border border-border10 text-text10 text-md font-bold leading-tight pc:hover:bg-primary pc:hover:text-white"
      href={url}
    >
      <span className="pr-2 w-full">{label}</span>
      {isInternalLink(url) ? (
        <SvgArrowIconSizeM />
      ) : (
        <svg
          className="externalIcon"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M15.499 9.50001C15.499 9.22386 15.7229 9 15.999 9H20.4991C20.7752 9 20.9991 9.22386 20.9991 9.50001V14.0001C20.9991 14.2762 20.7752 14.5001 20.4991 14.5001C20.2229 14.5001 19.9991 14.2762 19.9991 14.0001V10H15.999C15.7229 10 15.499 9.77615 15.499 9.50001Z"
            fill="#CC050B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9 11.5C9 11.2239 9.22386 11 9.50001 11H13.5001C13.7762 11 14.0001 11.2239 14.0001 11.5C14.0001 11.7762 13.7762 12 13.5001 12H10V20.0001H18.0001V16.5001C18.0001 16.2239 18.224 16.0001 18.5001 16.0001C18.7763 16.0001 19.0001 16.2239 19.0001 16.5001V20.5001C19.0001 20.7763 18.7763 21.0001 18.5001 21.0001H9.50001C9.22386 21.0001 9 20.7763 9 20.5001V11.5Z"
            fill="#CC050B"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M20.3527 9.64645C20.5479 9.84171 20.5479 10.1583 20.3527 10.3536L14.8526 15.8536C14.6573 16.0489 14.3407 16.0489 14.1455 15.8536C13.9502 15.6584 13.9502 15.3418 14.1455 15.1465L19.6455 9.64645C19.8408 9.45118 20.1574 9.45118 20.3527 9.64645Z"
            fill="#CC050B"
          />
        </svg>
      )}
    </LinkBase>
  )
}
