import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { ButtonSecondary } from '../../../components/atoms/Button/ButtonSecondary'
import { SEOIR } from '../../../components/data/SEO'
import { ENGLISH_TITLE } from '../../../components/data/English'

const data = [
  {
    label: '株式基本情報',
    url: '/ir/stock/information',
  },
  {
    label: '株価情報',
    url: 'https://finance.yahoo.co.jp/quote/7041.T',
  },
  {
    label: '株主総会',
    url: '/ir/stock/shareholders',
  },
  {
    label: '定款・株式取扱規程',
    url: '/ir/stock/regulation',
  },
  {
    label: '株式事務手続き',
    url: '/ir/stock/procedure',
  },
]

const Page = () => {
  return (
    <LayoutIR
      heading="株式・株主情報"
      headingEnglish={ENGLISH_TITLE.Stock}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '株式・株主情報', url: '/' },
      ]}
    >
      <div className="pc:pt-12 pc:px-8 pb-12 bg-white">
        <div className="grid pc:grid-cols-2 gap-4 pc:gap-6">
          {data.map((item) => (
            <ButtonSecondary key={item.url} label={item.label} url={item.url} />
          ))}
        </div>
      </div>
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => <SEOIR title="株式・株主情報" url="/ir/stock" />
